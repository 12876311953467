.loader-overlay {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-family: 'Montserrat', sans-serif;
    p{
        position: absolute;
        color: #333333;
        top: 55%;
    }
    
    .loader-container {
        display: inline-block;
        position: absolute;
        width: 50px;
        height: 50px;
        border: 3px solid #8b36bd;
        border-radius: 50%;
        border-top-color: #8b36bd8e;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
        @keyframes spin {
          to {
            -webkit-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes spin {
          to {
            -webkit-transform: rotate(360deg);
          }
        }
    }
}