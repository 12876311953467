@mixin mobile {
    @media screen and (max-width: 576px) {
      @content;
    }
  }
  @mixin tablet {
    @media screen and (min-width: 600px) and (max-width:1100px) {
        @content
    }}
.request-modal{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
   
    background-color: #00000079;

}
.request-alert-box{
    height: 35%;
    width: 30%;
    display: flex;
   padding: 0;
   margin: 0;
   border-radius: 5px;
        
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #fff;
    z-index: 1;
    .request-alert-box-body {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
 
        }
    .msg-icon {
        padding: 1rem;
        width: 2%;
        font-size: 1.5rem;
        color: #1368F6;
        
    }
    .msg-text{
       
        color: #5f5f5f;
        text-transform: capitalize;
        padding: 0rem .5rem;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.2rem;
    font-weight: 400;
    width: 80%;
    }
    .close{
        position: absolute;
        top: 0;
        right: 2%;
        cursor: pointer;
        padding: .5rem;
        font-size: 1.2rem;
        color: #1368F6;
    }
    .modal-btn {
        width: 100%;
        height: 13%;
        padding: .5rem 0rem;
        display: flex;
        justify-content: flex-end;
        background-color: #ececec;
        
        
    }
  
    .modal-close-btn {
        margin-right: 1rem;
            display: flex;
            height: 100%;
            align-items: center;
           color: #fff;
           padding: .5rem .7rem;
           font-weight: 500;
          
           font-size: 1rem;
           border: none;
           border-radius: 25px;
           cursor: pointer;
           font-family: 'Montserrat', sans-serif;
           background-color: #1368F6;
           transition: .3s all ease-in-out;
       
           &:hover {
               background-color: rgba(75,5,122,1);
           }
       
    }
    @include mobile {
        width: 80%;
    }
    @include tablet{
        width: 60%;
    }
}
.hide{
    display: none;
}
