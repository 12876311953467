@mixin mobile {
    @media screen and (max-width:576px) {
        @content
    }
}
@mixin tablet {
    @media screen and (min-width: 600px) and (max-width:1100px) {
        @content
    }}
.nav-container{
    display: flex;
    width: 100%;
    height: 12vh;
    align-items: center;
    justify-content: space-between;
    transition: .5s all ease-in-out;
    z-index: 2;
    
   
    .logo {
        padding: 0.4rem 1rem;
        
        font-family: 'Montserrat', sans-serif;
        color: #841ebe;
        a{
            text-decoration: none;
        text-transform: capitalize;
        color: #841ebe;
        &:focus{
            color: #1368F6;
        }
    }
    @include mobile {
        
        font-size: .9rem;
        
    }
    }
    .navlist{ width: 100%;
        
        height: 100%;
        list-style-type: none;
        display: flex;
        margin: 0;
        padding: 0rem 1rem;
        align-items: center;
        transition: .5s all ease-in-out;
        @include mobile {
            padding: 1rem;
        }
        .nav-item{
            margin: .2rem;
            
            .nav-link{
                &.active{
                    background-color: #841ebe;
                    color: #fff;
                }
            }
           
            &:hover {
                
                .nav-link {
                    background-color: #841ebe;
                    color: #fff;
                }
            }
          
        }
        .nav-item-hidden{
            display: none;
        }
        .nav-link{
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            color: #841ebe;
            text-decoration: none;
            margin: 0 .2rem;
            padding: .5rem 1rem;
            border-radius: .5rem;
            border: 2px solid transparent;
            transition: all ease-in-out .4s;
        }
    }
}
.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s all ease-in-out;
    .ham-btn {
      display: none;
        appearance: none;
        border: none;
        outline: none;
        background: none;
        margin-right: 1rem;
        z-index: 8;
       
        
        cursor: pointer;
        @include tablet {
            top: 3.5rem;
        }
        span {
            display: block;
            height: 3px;
            width: 25px;
            background-color: #841ebe;
            border-radius: .2rem;
            margin-bottom: 5px;
            transform-origin: 0 0 ;
            transition: .5s ;
        }
        &:hover :nth-child(2){
            
            transform: translateX(10px);
            background-color: #31A8FF;
        }
    }
    .ham-btn.isActive span:nth-child(1){
        transform: translate(2px, -4px) rotate(45deg);
        background-color: #31A8FF;
    }
    .ham-btn.isActive span:nth-child(2){
        opacity: 0;
        transform:translate(15px)
    }
    .ham-btn.isActive span:nth-child(3){
        transform: translate(-1px, -3px) rotate(-45deg);
        background-color: #31A8FF;
    }
}


.non-active{
    border: 2px solid #ff0000;
}
@media only screen and (max-width:760px) {
    .navbar {
        .ham-btn{
            display: block;
        }
        
    }
    .nav-container{
        
        .navlist {
            display: none;
        }
        
    }
    .nav-container.navToggled {
        flex-direction: column;
        height: 100%;
        .ham-btn{
            position: absolute;
            right: 0rem;
            top: 2.2rem;
        }
        .navlist{
            display: flex;
           
           flex-direction: column;
           .nav-item{
            margin-top: .9rem;
           }
        }
       
    }


}
